import request from '@/utils/request'


// 查询引导页和启动页列表
export function listGuidePage(query) {
  return request({
    url: '/valve/guide/page/list',
    method: 'get',
    params: query
  })
}

// 查询引导页和启动页分页
export function pageGuidePage(query) {
  return request({
    url: '/valve/guide/page/page',
    method: 'get',
    params: query
  })
}

// 查询引导页和启动页详细
export function getGuidePage(data) {
  return request({
    url: '/valve/guide/page/detail',
    method: 'get',
    params: data
  })
}

// 新增引导页和启动页
export function addGuidePage(data) {
  return request({
    url: '/valve/guide/page/add',
    method: 'post',
    data: data
  })
}

// 修改引导页和启动页
export function updateGuidePage(data) {
  return request({
    url: '/valve/guide/page/edit',
    method: 'post',
    data: data
  })
}

// 删除引导页和启动页
export function delGuidePage(data) {
  return request({
    url: '/valve/guide/page/delete',
    method: 'post',
    data: data
  })
}
